<template>
    <div>
      <div>
        <div class="dispflex" style="margin-right: 2%;margin-left: 1%;">
          <div class="dk_icon" style="width: 10%">
         
            <!-- <span class="has-float-label" style="margin-top: 4%">
              <select
                class="form-control form-input"
                id="deptlistid"
              >
                <option :value="null">-- Select--</option>
                <option
                  v-for="(item, index) in gradeTypes"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.value }}
                </option>
              </select>
              <label for="clslistid">Select Type </label>
            </span> -->
          </div>
        
     
          <div class="text-right dk_iconsml widjetcontent">
            <button
              style="width: 42%;margin-left: 6px;"
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="openGradingMarkPopUp"
            
            >
             <span>Add</span>
            </button>
            <button
              style="width: 47%;margin-left: 5px;"
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="updateGradeSetUp"
            
            >
              <span>Update</span>
            </button>
          
          </div>
        </div>
      
        <div class="tbllist mt-1">
          <div class="crtfeeplancont" style="padding: 0 13px">
            <div
              class="widjethdr dispflex"
              style="align-items: baseline; padding: 10px 0"
            ></div>
            <div class="restable">
              <div class="resrow resheader">
                <!-- <div class="cell">Type</div> -->
                <div class="cell">Grade</div>
                <div class="cell">From</div>
                      <div class="cell">To</div>
                <div class="cell">Action</div>
              </div>
              <div
                class="resrow"
                v-for="(data, index) in allGradeList.gradeMarkList"
                :key="index"
              >
                <!-- <div class="cell">
                  {{ data.gradeType  }}
                </div> -->
                <div class="cell">
                  <input
                v-model="data.grade"
                type="text"
                class="form-control"
                id="stdFirstName"
              />
                 
                </div>
                <div class="cell" data-title="getUserName">
                  <input
                v-model="data.from"
                type="text"
                class="form-control"
                id="stdFirstName"
              />
                  </div>
                  <div  class="cell" data-title="Role">
                    <input
                v-model="data.to"
                type="text"
                class="form-control"
                id="stdFirstName"
              />
                  </div>
                <div class="cell">
                
                 
                  <button @click.prevent="openGradingMarkEditPopUp(index)"
                    class="btn round orange tooltipt"
                  >
                    <i class="fas fa-minus-circle"></i
                    ><span class="tooltiptext">Remove</span>
                  </button>
  
                  <!-- <button
                    class="btn round red tooltipt"
                  >
                    <i class="far fa-trash-alt"></i>
                    <span class="tooltiptext">Delete</span>
                  </button> -->
                </div>
              </div>
            </div>
            <!-- <div
              class="widjet"
              style="height: 198px; box-shadow: none"
            >
              <div style="padding-top: 92px">
                <span style="margin-left: 42%">No Data Found</span>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <b-modal id="gradeSetting" style="width: 40%" size="sm">
        <button type="button" class="close"  @click.prevent="closeGradingMarkPopUp">×</button>

      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Grade Setting</h2>
        <br />
        <div class="form-row">
          <div class="form-group input-group col-md-6 mt-3">
            <span class="has-float-label">
              <select
                class="form-control form-input"
                id="Reportingid"
                disabled
                name="Reporting"
                v-model="gradeType"
                @change="clearValues"
              >
                <option :value="null">-- Select --</option>
                <option
                  v-for="(item, index) in gradeTypes"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>

              <label for="acyearid"
                >Grade Setting Type <span class="required">*</span></label
              >
            </span>
          </div>
          <!-- <div class="form-row" > -->
          <div v-if="gradeType == 'Mark'" class="form-group input-group col-md-3 mt-3">
            <span class="has-float-label">
              <input
                @input="setTotalRange(totalMarks)"
                v-model="totalMarks"

                type="number"
                class="form-control form-input"
                id="stdFirstName"
              />
              <label for="clsnameid">Total Marks <span class="required">*</span></label>
            </span>
          <!-- </div> -->
        </div>
        </div>
        <br />
        <!-- <div class="form-row" v-if="gradeType == 'GRADE SETUP'">
          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              <input
                v-model="gradeSetupDetails.marks"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              />
              <label for="clsnameid">Mark <span class="required">*</span></label>
            </span>
          </div>
        </div> -->
          <div class="form-row" v-if="gradeType == 'Mark'">

          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              <select
                  @change="setTotalToRange(totalMarks,gradeSetupDetails.from)"

                    v-model="gradeSetupDetails.from"
                    class="form-control form-input"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in rangeFromList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

              <!-- <input
                v-model="gradeSetupDetails.from"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              /> -->
              <label for="clsnameid"
                >FROM <span class="required">*</span></label
              >
            </span>
          </div>
          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              
              <select

                  v-model="gradeSetupDetails.to"
                    class="form-control form-input"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in rangeToList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
              <!-- <input
                v-model="gradeSetupDetails.to"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              /> -->
              <label for="clsnameid">TO <span class="required">*</span></label>
            </span>
          </div>
         
          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              <input
                v-model="gradeSetupDetails.grade"
                type="text"
                class="form-control form-input"
                id="stdFirstName"
              />
              <label for="clsnameid"
                >Grade <span class="required">*</span></label
              >
            </span>
          </div>
         
          <div class="form-group input-group col-md-2 mt-3">
            <button
              :disabled="!gradeSetupDetails.grade"
              style="width: 45%"
              type="button"
              class="btn btnsml"
              @click.prevent="addMarksRange"
            >
              Add
            </button>
          </div>
        </div>
        <div class="form-row" v-if="gradeType == 'Mark'">
          <div class="form-group col-md-12 mt-3 pr-md-3">
            <div class="tbllist mt-4">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell" >Mark Range</div>
                  <div class="cell">Grade</div>
                  <div class="cell" style="text-align: center;">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(data, index) in gradeMarkList"
                  :key="index"
                >
                  <div class="cell" data-title="Teacher">
                    {{ data.from + " - " + data.to }}
                  </div>
                  <div class="cell" data-title="Teacher" style="width: 164px;">
                    <input
                v-model="data.grade"
                type="text"
                class="form-control"
                id="stdFirstName"
              />
                    <!-- {{ data.grade }} -->
                  </div>
                  <div class="cell" data-title="Action" style="text-align: center;">
                   
                    <button 
                      class="btn round red tooltipt"
                      @click="remove(index,data.from,data.to)"
                    >
                      <i class="far fa-trash-alt"></i>
                      <span class="tooltiptext">Delete</span>
                    </button>
                    <!-- :disabled="index != (gradeMarkList.length - 1)" -->

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
        <div class="form-row" v-if="gradeType == 'Attendance Grading Scale'">
          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              <input
                v-model="gradeSetupDetails.from"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              />
              <label for="clsnameid"
                >FROM Mark <span class="required">*</span></label
              >
            </span>
          </div>
          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              <input
                v-model="gradeSetupDetails.to"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              />
              <label for="clsnameid"
                >TO Mark<span class="required">*</span></label
              >
            </span>
          </div>
          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              <input
                v-model="gradeSetupDetails.marks"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              />
              <label for="clsnameid">Mark <span class="required">*</span></label>
            </span>
          </div>
          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              <input
                v-model="gradeSetupDetails.grade"
                type="text"
                class="form-control form-input"
                id="stdFirstName"
              />
              <label for="clsnameid"
                >Grade <span class="required">*</span></label
              >
            </span>
          </div>
          <div class="form-group input-group col-md-2 mt-3">
            <button
              :disabled="
                !gradeSetupDetails.from ||
                !gradeSetupDetails.to ||
                !gradeSetupDetails.marks ||
                !gradeSetupDetails.grade
              "
              style="width: 45%"
              type="button"
              class="btn btnsml"
              @click.prevent="saveGradeSetUp('attendance')"
            >
              Save
            </button>
          </div>
        </div>
        <div class="form-row" v-if="gradeType == 'Attendance Grading Scale'">
          <div class="form-group col-md-12 mt-3 pr-md-3">
            <div class="tbllist mt-4">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Mark Range</div>
                  <div class="cell">Total Mark</div>
                  <div class="cell">Grade</div>
                  <div class="cell">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(data, index) in gradeAttendanceList"
                  :key="index"
                >
                  <div class="cell" data-title="Teacher">
                    {{ data.from + " - " + data.to }}
                  </div>
                  <div class="cell" data-title="Teacher">
                    {{ data.marks }}
                  </div>
                  <div class="cell" data-title="Teacher">
                    <input
                v-model="data.grade"
                type="text"
                class="form-control"
                id="stdFirstName"
              />
                    <!-- {{ data.grade }} -->
                  </div>
                  <div class="cell" data-title="Action">
                   
                   <button
                     class="btn round red tooltipt"
                    
                   >
                     <i class="far fa-trash-alt"></i>
                     <span class="tooltiptext">Delete</span>
                   </button>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="pt-3 text-right dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
      
        <button @click="saveGradeSetUp('mark')" type="button" class="btn btnsml" :disabled="gradeMarkList.length == 0">
           Save
          </button>
          <button @click="closeGradingMarkPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="editGradeSetting" style="width: 40%" size="sm">
      <button type="button" class="close"  @click.prevent="closeGradingMarkEditPopUp">×</button>

      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Update Grade Setting</h2>
        <br />
        <div class="form-row">
          <div class="form-group input-group col-md-6 mt-3">
            <span class="has-float-label">
              <select
                class="form-control form-input"
                id="Reportingid"
                disabled
                name="Reporting"
                v-model="gradeType"
              >
                <option :value="null">-- Select --</option>
                <option
                  v-for="(item, index) in gradeTypes"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>

              <label for="acyearid"
                >Grade Setting Type <span class="required">*</span></label
              >
            </span>
          </div>
          <!-- <div class="form-row" > -->
          <div  class="form-group input-group col-md-3 mt-3">
            <span class="has-float-label">
              <input
                
                @input="setTotalRange(totalMarks)"
                v-model="totalMarks"

                type="number"
                class="form-control form-input"
                id="stdFirstName"
              />
              <label for="clsnameid">Marks <span class="required">*</span></label>
            </span>
        </div>
        </div>
        <br />
       
          <div class="form-row" >

          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              <select
                  @change="setTotalToRange(totalMarks,gradeSetupDetails.from)"

                    v-model="gradeSetupDetails.from"
                    class="form-control form-input"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in rangeFromList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

              <!-- <input
                v-model="gradeSetupDetails.from"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              /> -->
              <label for="clsnameid"
                >FROM <span class="required">*</span></label
              >
            </span>
          </div>
          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              
              <select

                  v-model="gradeSetupDetails.to"
                    class="form-control form-input"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in rangeToList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
              <!-- <input
                v-model="gradeSetupDetails.to"
                type="number"
                class="form-control form-input"
                id="stdFirstName"
              /> -->
              <label for="clsnameid">TO <span class="required">*</span></label>
            </span>
          </div>
         
          <div class="form-group input-group col-md-2 mt-3">
            <span class="has-float-label">
              <input
                v-model="gradeSetupDetails.grade"
                type="text"
                class="form-control form-input"
                id="stdFirstName"
              />
              <label for="clsnameid"
                >Grade <span class="required">*</span></label
              >
            </span>
          </div>
         
          <div class="form-group input-group col-md-2 mt-3">
            <button
              :disabled="!gradeSetupDetails.grade"
              style="width: 45%"
              type="button"
              class="btn btnsml"
              @click.prevent="addMarksRangeEdit"
            >
              Add
            </button>
          </div>
        </div>
        <div class="form-row" >
          <div class="form-group col-md-12 mt-3 pr-md-3">
            <div class="tbllist mt-4">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">Mark Range</div>
                  <div class="cell">Grade</div>
                  <div class="cell" style="text-align: center;">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(data, index) in gradeMarkList"
                  :key="index"
                >
                  <div class="cell" data-title="Teacher">
                    {{ data.from + " - " + data.to }}
                  </div>
                  <div class="cell" data-title="Teacher" style="width: 164px;">
                    <input
                v-model="data.grade"
                type="text"
                class="form-control"
                id="stdFirstName"
              />
                    <!-- {{ data.grade }} -->
                  </div>
                  <div class="cell" data-title="Action" style="text-align: center;">
                   
                    <button 
                      class="btn round red tooltipt"
                      @click="removeEdit(index,data.from,data.to)"
                    >
                      <i class="far fa-trash-alt"></i>
                      <span class="tooltiptext">Delete</span>
                    </button>
                    <!-- :disabled="index != (gradeMarkList.length - 1)" -->

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      
        <div
          class="pt-3 text-right dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
      
        <button @click="updateGradeSetUp" type="button" class="btn btnsml" :disabled="gradeMarkList.length == 0">
           Update
          </button>
          <button @click="closeGradingMarkEditPopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
      
    </div>
  </template>
  
  <script>
  import "vue-select/dist/vue-select.css";
  import FullCalendar from "@fullcalendar/vue";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import ViService from "@/services/ViService";
  import secureUI from "../../../utils/secureUI";
  import draggable from "vuedraggable";
  
  export default {
    name: "teacher",
    // props: {
    //   classId: {
    //     type: String,
    //   },
    //   classRoom: {
    //     type: String,
    //   },
    //   assignedSubjects: {
    //     type: Array
    //   }
    // },
    data() {
      return {
      editId: null,
      totalMarks: null,
      allFromRangeList: [],
      totalMarksEntered: [],
      userDetail: {},
      students: [],
      section: {},
      rangeFromList: [],
      rangeToList: [],
      gradeMarkList: [],
      gradeAttendanceList: [],
      allGradeList: {},
      gradeType: 'Mark',
      gradeTypes: [
        {
            label: 'Academics Grade Setup',
            value: 'Mark'
        },
        // {
        //     label: 'Attendance Grade Setup',
        //     value: 'Attendance'
        // }
        // "Mark GRADE SETUP",
        // "Attendance GRADE SETUP"
      ],
      gradeSetupDetails: {
        from: "",
        to: "",
        grade: "",
      },
      };
    },
    created() {
      this.getGradeSetupDetails();

      //this.getSubjectList();
      //this.getClassTerm();
    },
    computed: {
      styles() {
        var brwHeight = window.innerHeight;
        return {
          height: brwHeight - 90 + "px",
        };
      },
    },
  
    methods: {
      clearValues(){
        this.rangeFromList = [],
        this.rangeToList = [],
        this.gradeMarkList = [],
        this.totalMarks = null
        this.gradeSetupDetails = {
            from: "",
            to: "",
            grade: "",
      };
      },
      setTotalRange(data){
      this.rangeFromList = [],
      this.rangeToList = [],
      this.gradeMarkList = [],
      this.allFromRangeList = [],
      this.totalMarksEntered = []

      if(data){
      for (var i = 0; i <= data; i++) {
        this.rangeFromList.push(i);
      }
    
      this.allFromRangeList = [...this.rangeFromList]

      this.gradeSetupDetails = {
            from: "",
            to: "",
            marks: null,
            grade: "",
      };

     }
    },

    setTotalToRange(totalMark,fromRange){
      this.rangeToList = []
     

      if(totalMark){
      for (var i = fromRange; i <= totalMark; i++) {
        this.rangeToList.push(i);
      }

     }
    },
    remove(index,from,to){
      let removeValues = []
     // if(from && to){
      for (var i = from; i <= to; i++) {
        this.rangeFromList.push(i);
        removeValues.push(i)
      }
      if(this.rangeFromList.length > 0){
        this.rangeFromList.sort(function(a, b) {
       return a - b;
      });
      }
    // }
    this.gradeMarkList.splice(index,1);
 
    let finalTotalMarksEntered = this.removeDuplicateValues(removeValues)
    this.totalMarksEntered = finalTotalMarksEntered

    },
     removeDuplicateValues(arr1) {
      let arr2 = [...this.totalMarksEntered]
      const modifiedArray = arr2.filter((number) => !arr1.includes(number));
      return modifiedArray;
    },
    removeEdit(index,from,to){
      let removeValues = []

     // if(from && to){
      for (var i = from; i <= to; i++) {
        this.rangeFromList.push(i);
        removeValues.push(i)

      }
      if(this.rangeFromList.length > 0){
        this.rangeFromList.sort(function(a, b) {
       return a - b;
      });
      }
    // }
    this.gradeMarkList.splice(index,1);

    let finalTotalMarksEntered = this.removeDuplicateValues(removeValues)
    this.totalMarksEntered = finalTotalMarksEntered

    },
    
    closeGradingMarkPopUp() {
      this.gradeType = 'Mark'
      this.totalMarksEntered = []
      this.$bvModal.hide("gradeSetting");
      this.gradeSetupDetails = {
            from: "",
            to: "",
            marks: null,
            grade: "",
      };
     
    },
    closeGradingMarkEditPopUp() {
      this.gradeType = 'Mark'
      this.gradeMarkList = []
      this.totalMarksEntered = []
      this.totalMarks = null
      this.editId = null
      this.$bvModal.hide("editGradeSetting");
      this.gradeSetupDetails = {
            from: "",
            to: "",
            marks: null,
            grade: "",
      };
     
    },
  //  
    addMarksRange(){
      let marksRange = []

      for (var i = this.gradeSetupDetails.from; i <= this.gradeSetupDetails.to; i++) {
        marksRange.push(i);
      }
   

    const checkRangeNumbers = this.findCommonNumbers(marksRange,this.totalMarksEntered)

    if(checkRangeNumbers.length == 0){

      this.gradeMarkList.push({
            from: this.gradeSetupDetails.from,
            to: this.gradeSetupDetails.to,
            grade: this.gradeSetupDetails.grade
     })

     this.rangeFromList = []
     this.rangeToList = []

      if(this.gradeSetupDetails.to){

       this.gradeSetupDetails.to = this.gradeSetupDetails.to + 1
    
      for (var i = this.gradeSetupDetails.from; i < this.gradeSetupDetails.to; i++) {
        this.totalMarksEntered.push(i);
      }

      let final = this.findMissingNumbers(this.allFromRangeList,this.totalMarksEntered)
      this.rangeFromList = [...final]

      }

     this.gradeSetupDetails = {
            from: "",
            to: "",
            grade: "",
      };
    }else{
      this.$toasted.error("The From/To Marks entered are already added to a range, please check. ");

    }
    },
    addMarksRangeEdit(){
      let marksRange = []

      for (var i = this.gradeSetupDetails.from; i <= this.gradeSetupDetails.to; i++) {
        marksRange.push(i);
      }

    const checkRangeNumbers = this.findCommonNumbers(marksRange,this.totalMarksEntered)

    if(checkRangeNumbers.length == 0){

      this.gradeMarkList.push({
            from: this.gradeSetupDetails.from,
            to: this.gradeSetupDetails.to,
            grade: this.gradeSetupDetails.grade
     })

     this.rangeFromList = []
     this.rangeToList = []


      if(this.gradeSetupDetails.to){

       this.gradeSetupDetails.to = this.gradeSetupDetails.to + 1
    
      for (var i = this.gradeSetupDetails.from; i < this.gradeSetupDetails.to; i++) {
        this.totalMarksEntered.push(i);
      }

      let final = this.findMissingNumbers(this.allFromRangeList,this.totalMarksEntered)
      this.rangeFromList = [...final]

      }

     this.gradeSetupDetails = {
            from: "",
            to: "",
            grade: "",
      };
    }else{
      this.$toasted.error("The From/To Marks entered are already added to a range, please check. ");

    }
    },
    
    openGradingMarkPopUp() {
     // this.gradeType = null
    //  this.$bvModal.show("gradeSetting");
    this.allGradeList.gradeMarkList.push({
            from: "",
            to: "",
            grade: "",
      })
    },
    openGradingMarkEditPopUp(index) {
      this.allGradeList.gradeMarkList.splice(index,1);

      // this.allFromRangeList = []
      // this.rangeFromList = []
      // this.rangeToList = []
     // let details = this.allGradeList[index]
      // this.editId = details._id
      // this.gradeType = details.gradeType
      // this.gradeMarkList = details.gradeMarkList

      // this.totalMarks = details.totalMarks

      // for (var i = 0; i <= this.totalMarks; i++) {
      //   this.allFromRangeList.push(i);
      // }

      // for (var i = 0; i <= this.totalMarks; i++) {
      //   this.totalMarksEntered.push(i);
      // }

    
      // //this.allFromRangeList = [...this.rangeFromList]

      // this.$bvModal.show("editGradeSetting");
      // // this.gradeSetupDetails = {
      // //       from: "",
      // //       to: "",
      // //       marks: null,
      // //       grade: "",
      // // };
    },
    

    async getGradeSetupDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        // let obj = {
        //   class: this.classId,
        //   classroom: this.classRoom,
        // };

        const response = await ViService.viXPost(
          "/academic/getGradeSetupDetails",
          { type: 'all'},
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          const data = secureUI.secureGet(response.data);
          this.allGradeList = data
          //this.gradeMarkList = data.filter((x) => x.type == "mark");
          //this.gradeAttendanceList = data.filter((x) => x.type != "mark");
        } else {
          this.$toasted.error(response.message);
        }
      }
    },

    async saveGradeSetUp(type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        //let rangeFromAndTo = []

        // let fromRangeData = this.gradeMarkList.map( x => x.from)
        // let toRangeData = this.gradeMarkList.map( x => x.to)
        // rangeFromAndTo = rangeFromAndTo.concat(fromRangeData)
        // rangeFromAndTo = rangeFromAndTo.concat(toRangeData)

        let finalRangeMarks = this.findMissingNumbers(this.allFromRangeList,this.totalMarksEntered)

        if(finalRangeMarks.length == 0){
          let obj = {
          // class: this.classId,
          // classroom: this.classRoom,
          type: type,
          gradeMarkList: this.gradeMarkList,
          totalMarks : this.totalMarks,
          gradeType: this.gradeType
        };

        const response = await ViService.viXPost(
          "/academic/gradeSetup/save",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.$bvModal.hide("gradeSetting");

          this.getGradeSetupDetails();
           this.gradeMarkList = [],
           this.totalMarks = null,
           this.gradeType = 'Mark'
        } else {
          this.$toasted.error(response.message);
        }

         
        }else{
          this.$toasted.error(`Please enter the complete range of marks from 0 to ${this.totalMarks}`);

        }

        

      }
    },
    async updateGradeSetUp() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        

       // let finalRangeMarks = this.findMissingNumbers(this.allFromRangeList,this.totalMarksEntered)

       // if(finalRangeMarks.length == 0){
          let obj = {
          _id: this.allGradeList._id,
          gradeMarkList: this.allGradeList.gradeMarkList,
        };

        const response = await ViService.viXPost(
          "/academic/gradeSetup/update",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
         // this.$bvModal.hide("editGradeSetting");
         // this.editId = null
          this.getGradeSetupDetails();
          //  this.gradeMarkList = [],
          //  this.totalMarks = null,
          //  this.gradeType = 'Mark'
        } else {
          this.$toasted.error(response.message);
        }

         
        // }else{
        //   this.$toasted.error(` Grade setup is missing for certain range in ${this.totalMarks}`);

        // }

        

      }
    },
    findMissingNumbers(arr1, arr2) {
          const missingNumbers = [];

          const set = new Set(arr2);

          for (const num of arr1) {
              if (!set.has(num)) {
                  missingNumbers.push(num);
              }
          }

          return missingNumbers;
     },
     findCommonNumbers(arr1, arr2) {
    const commonNumbers = [];

    const set = new Set(arr1);

    for (const num of arr2) {
        if (set.has(num)) {
            commonNumbers.push(num);
        }
    }

    return commonNumbers;
     }

    },
    components: {
      'app-dragabble': draggable
    },
  };
  </script>
  